<template>
  <div class="bg-white p-4 md:p-6  rounded-lg modal-md pb-4">
    <div class="text-xl my-8 mt-4 font-semibold font-merri text-center">
      {{ data.makeActive ? 'Welcome back 😻' : 'Time for a cat nap...' }}
    </div>
    <p>
      Are you sure you want to make
      <span class="font-semibold">{{ data.cat.name }}</span> plan
      {{ data.makeActive ? 'active' : 'inactive' }}?
    </p>
    <p v-if="data.makeActive">
      This will mean that their food will be sent with your next box.
    </p>
    <p v-else>
      This will mean that their food will not be sent untill you make them
      active again.
    </p>
    <form @submit.prevent="makeInactive" novalidate="true">
      <loading-button
        :is-loading="isLoading"
        :is-enabled="true"
        :is-full="true"
        class="mt-4"
        label="Confirm"
      />
    </form>
    <div class="text-center text-gray-600 mt-6">
      <a @click="closeModal">Cancel</a>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';
import http from '@/http';
import { mapGetters } from 'vuex';
import { cloneDeep, find } from 'lodash/fp';
export default {
  props: ['data'],
  components: {
    LoadingButton
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    })
  },
  methods: {
    makeInactive: function() {
      this.isLoading = true;
      const items = cloneDeep(this.subscription.items);
      const selectedCat = find(['id', this.data.cat.id], items);

      selectedCat.active = this.data.makeActive;

      http
        .put(this.subscription.links.adjust, {
          items: items,
          delivery: this.subscription.deliveryTypeUserSelected
        })
        .then(() => {
          this.$store.dispatch('sendMpEvent', {
            event: `Make cat ${
              this.data.makeActive ? 'active' : 'inactive'
            } - Success`,
            properties: {
              location: this.$route.name,
              catId: this.data.cat.id,
              cat: this.data.cat.name,
              type: 'EVT'
            }
          });

          this.$store.dispatch('getSubscription').then(() => {
            this.isLoading = false;
            this.closeModal();
          });
        })
        .catch(error => {
          this.isLoading = false;
          this.$store.dispatch('sendMpEvent', {
            event: `Make cat ${
              this.data.makeActive ? 'active' : 'inactive'
            } - Error`,
            properties: {
              location: this.$route.name,
              catId: this.data.cat.id,
              cat: this.data.cat.name,
              error: error.response.data.error.message
                ? error.response.data.error.message
                : 'Unknown Error',
              type: 'EVT'
            }
          });

          throw Error(error.response.data.error.message);
        });
    },
    closeModal: function() {
      this.$parent.$emit('make-close');
    }
  },

  mounted() {
    this.$store.dispatch('sendMpEvent', {
      event: `Make cat ${this.data.makeActive ? 'active' : 'inactive'} - View`,
      properties: {
        location: this.$route.name,
        catId: this.data.cat.id,
        cat: this.data.cat.name,
        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
